




























































































import Vue from "vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";
import Wallet from "@zubut/common/src/models/wallet/wallet";
import Client from "@/services/clients";
import { formatMoney } from "@/utils/money";
import * as userMutations from "@/store/modules/user/mutations-types";

export default Vue.extend({
  name: "WalletBalance",
  components: {
    ZModal
  },
  props: {
    size: {
      type: String,
      default: "lg",
      validator: val => ["sm", "lg"].indexOf(val) > -1
    }
  },

  data() {
    return {
      walletBackgroundImage: require("@/assets/img/wallet-background.png") as string,
      walletAmount: null as null | string,
      clabe: null as null | string,
      loading: false,
      enablerRedirect: true,
      activating: false,
      hovered: false
    };
  },

  computed: {
    buttonSize(): string {
      if (this.size === "sm") {
        return "sm";
      }
      return "md";
    },
    isZubut(): boolean {
      return this.$store.getters["isZubut"];
    }
  },

  mounted() {
    this.getWallletData();
  },

  methods: {
    getWallletData() {
      this.loading = true;
      // TODO: Move logic to an action
      Client.getWallletData(this.$store.getters["auth/getUserId"])
        // @ts-ignore
        .then((res: Wallet) => {
          this.walletAmount = formatMoney(res.amount / 100);
          this.clabe = res.clabeReference;
          this.$store.commit(`user/${userMutations.UPDATE_WALLET}`, res);
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },

    updateWallet() {
      this.enablerRedirect = false;
      this.getWallletData();
    },

    handleClick() {
      if (this.enablerRedirect) {
        this.$router.push({ name: "profile" });
      }
      this.enablerRedirect = true;
    },

    enableSPEI() {
      this.enablerRedirect = false;
      this.activating = true;
      this.$store
        .dispatch("user/enableSPEI")
        .then(res => {
          this.clabe = res.clabeReference;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.activating = false;
        });
    },

    handleAskClick() {
      this.enablerRedirect = false;
      this.hovered = true;
    },

    hideModal() {
      this.hovered = false;
    }
  }
});
